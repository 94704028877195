<template>
  <div
    v-if="variables.length"
    class="variables"
  >
    <div
      v-for="variable in variables"
      :key="variable.name"
      v-b-tooltip="`${variable.description}`"
      class="variables__item"
      @click.prevent="$emit('chose', variable.name)"
    >
      {{ variable.name }}
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    variables: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/bootstrap-extended/variables';

.variables {
  display: flex;

  &__item {
    border-radius: $border-radius;
    margin-right: 4px;
    background: $primary;
    padding: 0px 5px;
    color: #fff;
    cursor: pointer;
  }
}
</style>
