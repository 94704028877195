<template>
  <div
    ref="box"
    class="box-structure"
  >
    <div class="box-structure__title">
      <div class="mr-1">
        <feather-icon
          icon="InfoIcon"
        />
      </div>
      <div>
        Структура наследования
      </div>
    </div>
    <div class="box-structure__inner">
      <div
        v-for="template in templates"
        :key="template.slug"
      >
        <templates-structure-item
          :template="template"
        />
      </div>
    </div>
    <div
      class="box-structure__footer"
      @click.prevent="toggleHeight"
    >
      <feather-icon
        icon="MoreHorizontalIcon"
      />
    </div>
  </div>
</template>

<script>
import TemplatesStructureItem from './TemplatesStructureItem.vue'

export default {
  components: {
    TemplatesStructureItem,
  },
  props: {
    templates: {
      type: Array,
      required: true,
    },
  },
  methods: {
    toggleHeight() {
      if (this.$refs.box.clientHeight === 230) {
        this.$refs.box.style.maxHeight = `${this.$refs.box.scrollHeight}px`
      } else {
        this.$refs.box.style.maxHeight = '230px'
      }
    },
  },
}
</script>
