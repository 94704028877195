<template>
  <div>
    <div
      class="accordion-table__tr"
      :class="[{'is-hover': $store.state.templates.templateHoverSlug === template.slug}]"
      @mouseover.stop="onMouseEnter(template.slug)"
      @mouseout.stop="onMouseLeave(template.slug)"
    >
      <div
        class="accordion-table__td"
        :style="{'width': `calc(35% - ${level * 50}px)`}"
      >
        <div class="accordion-table__icon">
          <feather-icon
            icon="LayoutIcon"
            class="mr-1"
          />
        </div>
        {{ template.title }}
      </div>
      <div class="accordion-table__td">
        <super-tag
          :level="3"
        >
          {{ template.inherits_meta_template ? 'Авто' : 'Кастом' }}
        </super-tag>
      </div>
      <div class="accordion-table__td">
        <super-tag>
          {{ template.inherits_content_template ? 'Авто' : 'Кастом' }}
        </super-tag>
      </div>
      <div class="accordion-table__td">
        <super-tag
          :level="level"
        />
      </div>
      <div class="accordion-table__td">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="d-flex align-items-center">
            <b-button
              v-b-tooltip.hover.left="'Редактировать'"
              variant="link"
              size="sm"
              @click.stop="onOpenEdit(template.slug)"
            >
              <feather-icon
                icon="Edit3Icon"
                size="18"
              />
            </b-button>
            <b-button
              v-b-tooltip.hover.left="'Просмотр'"
              variant="link"
              size="sm"
              @click.stop="onOpenShow(template.slug)"
            >
              <feather-icon
                icon="EyeIcon"
                size="18"
              />
            </b-button>
          </div>
          <feather-icon
            class="accordion-table__chevron"
            icon="ChevronDownIcon"
            size="21"
          />
        </div>
      </div>
    </div>
    <template-edit-popup
      v-if="showEditPopup"
      :template-slug="template.slug"
      :level="level"
      @show="onOpenShow($event)"
      @hidden="showEditPopup = false"
      @updated="onUpdatedPage"
    />
    <template-show-popup
      v-if="showShowPopup"
      :template-slug="templateSlugToShow"
      @hidden="showShowPopup = false"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { BButton, VBTooltip } from 'bootstrap-vue'
import TemplateEditPopup from '../template-edit/TemplateEditPopup.vue'
import TemplateShowPopup from '../template-show/TemplateShowPopup.vue'
import SuperTag from '../components/SuperTag.vue'

export default {
  components: {
    BButton,
    TemplateEditPopup,
    SuperTag,
    TemplateShowPopup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    template: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showEditPopup: false,
      showShowPopup: false,
      templateSlugToShow: this.template.slug,
      templateSlugToEdit: this.template.slug,
    }
  },
  methods: {
    ...mapMutations({
      updateTemplateHoverSlug: 'templates/UPDATE_TEMPLATE_HOVER_SLUG',
    }),
    onMouseEnter(slug) {
      this.updateTemplateHoverSlug(slug)
    },
    onMouseLeave() {
      this.updateTemplateHoverSlug('')
    },
    onOpenShow(templateSlug) {
      this.templateSlugToShow = templateSlug
      this.showShowPopup = true
    },
    onOpenEdit(templateSlug) {
      this.templateSlugToEdit = templateSlug
      this.showEditPopup = true
    },
  },
}
</script>
