<template>
  <b-card>
    <div class="d-flex mb-4">
      <b-button
        v-for="tab in tabs"
        :key="tab.slug"
        :variant="$route.params.templatesType === tab.slug ? 'primary' : 'light'"
        :to="{name: 'templates', params: {templatesType: tab.slug}}"
        class="mr-1"
      >
        {{ tab.title }}
        <b-badge
          v-if="!hasFilled(templatesAlerts.find(alert => tab.slug === `${alert.type}s`))"
          variant="danger"
        >
          <feather-icon
            icon="AlertCircleIcon"
          />
        </b-badge>
      </b-button>
    </div>
    <div>
      <div v-if="$route.params.templatesType === 'services'">
        <Services />
      </div>
      <div v-if="$route.params.templatesType === 'brands'">
        <Brands />
      </div>
      <div v-if="$route.params.templatesType === 'models'">
        <Models />
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard, BButton, BBadge } from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import Services from './Services.vue'
import Brands from './Brands.vue'
import Models from './Models.vue'

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    Services,
    Brands,
    Models,
  },
  data() {
    return {
      tabs: [
        {
          slug: 'services',
          title: 'Шаблоны услуг',
        },
        {
          slug: 'brands',
          title: 'Шаблоны брендов',
        },
        {
          slug: 'models',
          title: 'Шаблоны моделей',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      templatesAlerts: 'templatesCheck/templatesAlerts',
    }),
  },
  watch: {
    '$route.params.templatesType': {
      handler() {
        this.init()
      },
    },
  },
  async created() {
    await this.fetchAlerts()
    this.init()
  },
  methods: {
    ...mapActions({
      fetchAlerts: 'templatesCheck/FETCH_ALERTS',
    }),
    init() {
      const { templatesType } = this.$route.params
      if (!this.tabs.some(tab => tab.slug === templatesType)) {
        this.$router.push({
          name: 'templates',
          params: {
            templatesType: 'services',
          },
        })
      }
    },
    hasFilled(checkData) {
      return (
        checkData
        && checkData.title_template_filled
        && checkData.description_template_filled
        && checkData.content_template_filled
      )
    },
  },
}
</script>
