<template>
  <div>
    <b-form-input
      ref="input"
      :value="value"
      class="highlight-input mb-1"
      @input="onInput"
    />
    <variables-list
      :variables="variables"
      @chose="onChose"
    />
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import { BFormInput } from 'bootstrap-vue'
import VariablesList from './VariablesList.vue'

export default {
  components: {
    VariablesList,
    BFormInput,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    variables: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'input',
    },
  },
  data() {
    return {
      highlight: [
        {
          text: new RegExp(/{{\s*?\w+\s*?}}/, 'gi'),
        },
        {
          text: new RegExp(/\[\[\s*?[\w;\s]+\s*?\]\]/, 'gi'),
        },
      ],
    }
  },
  methods: {
    onInput(event) {
      this.$emit('input', event)
    },
    onChose(variable) {
      const pos = this.$refs.input.selectionStart
      if (this.value) {
        const text1 = this.value.substring(0, pos)
        const text2 = this.value.substring(pos)
        this.$emit('input', `${text1} {{ ${variable} }} ${text2}`)
      } else {
        this.$emit('input', `{{ ${variable} }}`)
      }
    },
    getCaretPosition(editableDiv) {
      let caretPos = 0
      let sel; let
        range
      if (window.getSelection) {
        sel = window.getSelection()
        if (sel.rangeCount) {
          range = sel.getRangeAt(0)
          if (range.commonAncestorContainer.parentNode === editableDiv) {
            caretPos = range.endOffset
          }
        }
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange()
        if (range.parentElement() === editableDiv) {
          const tempEl = document.createElement('span')
          editableDiv.insertBefore(tempEl, editableDiv.firstChild)
          const tempRange = range.duplicate()
          tempRange.moveToElementText(tempEl)
          tempRange.setEndPoint('EndToEnd', range)
          caretPos = tempRange.text.length
        }
      }
      return caretPos
    },
  },
}
</script>

<style lang="scss">
  .highlight-input {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    &:focus {
      box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
    }

    span {
      font-family: Consolas,"courier new", monospace;
      color: #009900;
      background-color: #f1f1f1 !important;
      padding: 2px;
      font-size: 105%;
      border-radius: 4px;
    }

    &--textarea {
      min-height: 100px;
    }
  }
</style>
