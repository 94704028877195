<template>
  <b-modal
    id="edit-template-popup"
    :visible="visible"
    size="lg"
    ok-title="Сохранить изменения"
    cancel-title="Отменить"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <div class="d-flex align-items-center mb-4">
      <b-link
        v-if="parentTemplate.slug"
        class="mr-1"
        @click.prevent="$emit('show', parentTemplate.slug)"
      >
        <super-tag
          :level="level - 1"
        />
        {{ parentTemplate.title }}
      </b-link>
      <div
        v-if="parentTemplate.slug"
        class="mr-1"
      >
        <feather-icon
          icon="ArrowRightIcon"
        />
      </div>
      <div class="mr-1">
        <super-tag
          :level="level"
        />
        {{ currentTemplate.title }}
      </div>
    </div>

    <b-card-title
      title-tag="h2"
    >
      <span
        v-if="parentTemplate.title"
      >
        Дочернняя страницы страницы «{{ parentTemplate.title }}»
      </span>
      <span
        v-else
      >
        {{ currentTemplate.title }}
      </span>
    </b-card-title>
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-tabs
        pills
      >
        <b-tab active>
          <template #title>
            <feather-icon
              icon="TagIcon"
            />
            Мета-теги
          </template>
          <div class="py-2">
            <b-form-group v-if="parentTemplate.slug">
              <b-form-checkbox
                id="meta-tags"
                v-model="editTemplate.inherits_meta_template"
                name="meta-tags"
              >
                Использовать тэги из шаблона “{{ parentTemplate.title }}”
              </b-form-checkbox>
            </b-form-group>
            <b-form-group
              v-if="editTemplate.inherits_meta_template"
              label="Title"
              label-for="title_template"
            >
              <div class="highlight-input ProseMirror">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="highlightContent(parentTemplate.title_template)" />
              </div>
            </b-form-group>
            <b-form-group
              v-else
              label="Title"
              label-for="title_template"
            >
              <input-field-with-variables
                v-model="editTemplate.title_template"
                v-validate="'required'"
                name="title"
                :variables="currentTemplate.available_variables"
              />
              <small
                v-show="errors.has('title')"
                class="text-danger"
              >{{ errors.first('title') }}</small>
            </b-form-group>
            <b-form-group
              v-if="editTemplate.inherits_meta_template"
              label="Description"
              label-for="description_template"
            >
              <div class="highlight-input ProseMirror">
                <!-- eslint-disable-next-line vue/no-v-html -->
                <div v-html="highlightContent(parentTemplate.description_template)" />
              </div>
            </b-form-group>
            <b-form-group
              v-else
              label="Description"
              label-for="description_template"
            >
              <input-field-with-variables
                v-model="editTemplate.description_template"
                v-validate="'required'"
                type="textarea"
                :variables="currentTemplate.available_variables"
                name="description"
              />
              <small
                v-show="errors.has('description')"
                class="text-danger"
              >{{ errors.first('description') }}</small>
            </b-form-group>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <feather-icon
              icon="LayoutIcon"
            />
            Контент
          </template>
          <div class="py-2">
            <b-form-group v-if="editTemplate.parent_slug">
              <b-form-checkbox
                id="content"
                v-model="editTemplate.inherits_content_template"
                name="content"
              >
                Использовать тэги из шаблона “{{ parentTemplate.title }}”
              </b-form-checkbox>
            </b-form-group>
            <div
              v-if="editTemplate.inherits_content_template"
              class="highlight-input highlight-input--textarea ProseMirror"
            >
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="highlightContent(parentTemplate.content_template)" />
            </div>
            <b-form-group
              v-else
              label-for="content_template"
            >
              <CKEditor
                id="content_template"
                v-model="editTemplate.content_template"
                v-validate="'required'"
                name="content_template"
              />
              <small
                v-show="errors.has('content_template')"
                class="text-danger"
              >{{ errors.first('content_template') }}</small>
              <variables-list
                v-if="currentTemplate.available_variables"
                class="mt-1"
                :variables="currentTemplate.available_variables"
                @chose="insertVariable"
              />
            </b-form-group>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="mr-1">
              <feather-icon
                icon="SendIcon"
              />
              Форма
            </span>
          </template>
          <div class="py-2">
            <div class="mb-2">
              <b-form-group
                label="Заголовок формы"
                label-for="form_title"
              >
                <b-form-input
                  id="form_title"
                  v-model="editTemplate.form_title"
                  name="form_title"
                />
                <small
                  v-show="errors.has('form_title')"
                  class="text-danger"
                >{{ errors.first('form_title') }}</small>
              </b-form-group>
            </div>
            <div class="mb-2">
              <b-form-checkbox
                id="show_file_upload-tags"
                v-model="editTemplate.show_file_upload"
                name="show_file_upload"
              >
                Показывать поле для загрузки файлов
              </b-form-checkbox>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </form>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BCardTitle, BTabs, BTab, BFormGroup, BFormCheckbox, BLink, BFormInput,
} from 'bootstrap-vue'
import Template from '@/api/http/models/template/Template'
import { mapGetters, mapActions } from 'vuex'
import CKEditor from 'ckeditor4-vue'
import SuperTag from '../components/SuperTag.vue'
import InputFieldWithVariables from '../../../components/input-field-with-variables/InputFieldWithVariables.vue'
import VariablesList from '../../../components/input-field-with-variables/VariablesList.vue'

export default {
  components: {
    SuperTag,
    BCardTitle,
    BTabs,
    BTab,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BLink,
    InputFieldWithVariables,
    CKEditor: CKEditor.component,
    VariablesList,
  },
  props: {
    templateSlug: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      currentTemplate: {},
      editTemplate: {},
      parentTemplate: {},
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await Template.getOne(this.currentSite.slug, this.templateSlug)

    if (response.data) {
      response.data.content_template = response.data.content_template || ''
      this.currentTemplate = response.data
      this.editTemplate = { ...this.currentTemplate }

      if (this.currentTemplate.parent) {
        this.parentTemplate = this.currentTemplate.parent
        this.parentTemplate.content_template = this.parentTemplate.content_template || ''
      }

      this.visible = true
    }
  },
  methods: {
    ...mapActions({
      fetchAlerts: 'templatesCheck/FETCH_ALERTS',
    }),
    insertVariable(variable) {
      const key = Object.keys(window.CKEDITOR.instances)[0]
      // eslint-disable-next-line dot-notation
      window.CKEDITOR.instances[key].insertText(`{{ ${variable} }}`)
    },
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      if (this.editTemplate.content_template.match(/^(<p>(\s*|<p>\s*<\/p>)<\/p>|<p>\s*<\/p><p>\s*<\/p>)$/gi)) {
        this.editTemplate.content_template = ''
      }

      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Template.update(this.currentSite.slug, this.templateSlug, {
          inherits_meta_template: this.editTemplate.inherits_meta_template,
          inherits_content_template: this.editTemplate.inherits_content_template,
          content_template: this.editTemplate.content_template,
          title_template: this.editTemplate.title_template,
          description_template: this.editTemplate.description_template,
          show_file_upload: this.editTemplate.show_file_upload,
          form_title: this.editTemplate.form_title,
        })

        if (response.status === 'success') {
          this.$emit('updated', response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Шаблон обновлен',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('edit-template-popup')
          })
          await this.fetchAlerts()
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при обновлении',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
    highlightContent(content) {
      const text = content || ''
      return text
        .replace(/({{\s*?\w+\s*?}})/ig, '<code class="code-highlight code-highlight--success">$1</code>')
        .replace(/(\[\[\s*?[\w;\s]+\s*?\]\])/ig, '<code class="code-highlight code-highlight--success">$1</code>')
    },
  },
}
</script>
