<template>
  <div>
    <div class="accordion-table">
      <div class="accordion-table__head">
        <div class="accordion-table__tr">
          <div class="accordion-table__td">
            Шаблон
          </div>
          <div class="accordion-table__td">
            Мета-тэги
          </div>
          <div class="accordion-table__td">
            Контент
          </div>
          <div class="accordion-table__td">
            Тип
          </div>
          <div class="accordion-table__td">
            Действия
          </div>
        </div>
      </div>
      <templates-table-list
        :templates="templates"
        @loadMore="handleLoadMore"
      />
    </div>
  </div>
</template>

<script>
import TemplatesTableList from './TemplatesTableList.vue'

export default {
  components: {
    TemplatesTableList,
  },
  props: {
    templates: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleLoadMore(template) {
      this.$emit('loadMore', template)
    },
  },
}
</script>
