<template>
  <div
    class="box-item"
    :class="[`box-item--${typeBox}`, {'is-hover': $store.state.templates.templateHoverSlug === template.slug}]"
    @mouseover.stop="onMouseEnter(template.slug)"
    @mouseout.stop="onMouseLeave(template.slug)"
  >
    <div class="box-item__label">
      <feather-icon
        icon="TagIcon"
        size="12"
      />
      {{ typeBox === 'primary' ? 'Единый' : '' }}
    </div>
    <div
      v-if="template.children"
      class="box-item__children"
    >
      <div
        v-for="children in template.children"
        :key="children.slug"
      >
        <templates-structure-item
          :template="children"
          :level="level + 1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'TemplatesStructureItem',
  props: {
    template: {
      type: Object,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isHover: false,
    }
  },
  computed: {
    typeBox() {
      if (this.level === 0) return 'primary'
      if (this.level === 1) return 'warning'
      if (this.level === 2) return 'success'
      return 'primary'
    },
  },
  methods: {
    ...mapMutations({
      updateTemplateHoverSlug: 'templates/UPDATE_TEMPLATE_HOVER_SLUG',
    }),
    onMouseEnter(slug) {
      this.updateTemplateHoverSlug(slug)
    },
    onMouseLeave() {
      this.updateTemplateHoverSlug('')
    },
  },
}
</script>
