<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <div class="row mb-3">
      <div class="col-9">
        Единый шаблон услуг – основной шаблон для всех услуг на это вкладке. Наследование данных происходит от шаблона уровнем выше (см. пример)
      </div>
    </div>
    <div class="row">
      <div class="col-10">
        <templates-table
          :templates="templates"
          @loadMore="handleLoadMore"
        />
      </div>
      <div class="col-2">
        <templates-structure
          :templates="templates"
        />
      </div>
    </div>
  </b-overlay>
</template>

<script>
/* eslint no-param-reassign: [0] */

import { BOverlay } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Template from '@/api/http/models/template/Template'
import TemplatesTable from './templates-table/TemplatesTable.vue'
import TemplatesStructure from './templates-structure/TemplatesStructure.vue'

export default {
  components: {
    TemplatesTable,
    TemplatesStructure,
    BOverlay,
  },
  data() {
    return {
      showLoading: true,
      templates: [],
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await Template.search(this.currentSite.slug, {
      type: 'brand',
    })

    if (response.data) {
      this.templates = await this.createTemplates(response.data)
    }

    this.showLoading = false
  },
  methods: {
    async createTemplates(items, parentSlug = null) {
      const nested = []
      items.forEach(item => {
        if (item.parent_slug === parentSlug) {
          const children = this.createTemplates(items, item.slug)
          item.children = children.length ? children.sort((a, b) => a.order - b.order) : []
          item.meta = {
            current_page: 0,
            last_page: 1,
          }
          nested.push(item)
        }
      })
      nested.sort((a, b) => a.order - b.order)
      return nested
    },
    async handleLoadMore(template) {
      const page = template.meta.current_page + 1

      const response = await Template.search(this.currentSite.slug, {
        type: 'brand',
        parent_slug: template.slug,
        page,
      })

      template.meta = {
        ...response.meta,
      }

      response.data.forEach(item => {
        template.children.push({
          ...item,
          children: [],
          meta: {
            current_page: 0,
            last_page: 1,
          },
        })
      })
    },
  },
}
</script>
