<template>
  <b-modal
    id="show-template-popup"
    :visible="visible"
    size="lg"
    title="Просмотр шаблона"
    ok-only
    ok-title="Закрыть просмотр"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <b-card-title
      title-tag="h2"
    >
      {{ currentTemplate.title }}
    </b-card-title>

    <b-card-title
      title-tag="h3"
      class="mt-4"
    >
      <feather-icon
        icon="TagIcon"
      />
      Мета-тэги
    </b-card-title>
    <b-form-group
      label="Title"
    >
      <div class="highlight-input ProseMirror">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="highlightContent(currentTemplate.inherits_meta_template ? parentTemplate.title_template : currentTemplate.title_template)" />
      </div>
    </b-form-group>

    <b-form-group
      label="Description"
    >
      <div class="highlight-input ProseMirror">
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="highlightContent(currentTemplate.inherits_meta_template ? parentTemplate.description_template : currentTemplate.description_template)" />
      </div>
    </b-form-group>
    <b-card-title
      title-tag="h3"
      class="mt-4"
    >
      <feather-icon
        icon="LayoutIcon"
      />
      Контент
    </b-card-title>

    <editor
      :value="currentTemplate.inherits_content_template ? parentTemplate.content_template : currentTemplate.content_template"
      :disabled="true"
    />

    <b-card-title
      title-tag="h3"
      class="mt-4"
    >
      <feather-icon
        icon="SendIcon"
      />
      Форма
    </b-card-title>
    <div class="mb-4">
      <div class="mb-2">
        <b-form-group
          label="Заголовок формы"
          label-for="form_title"
        >
          <div class="highlight-input ProseMirror">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <div v-html="currentTemplate.form_title || '...'" />
          </div>
        </b-form-group>
      </div>
      <div class="mb-2">
        <b-form-checkbox
          id="show_file_upload-tags"
          v-model="currentTemplate.show_file_upload"
          name="show_file_upload"
          disabled
        >
          Показывать поле для загрузки файлов
        </b-form-checkbox>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  BCardTitle, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import Template from '@/api/http/models/template/Template'
import { mapGetters } from 'vuex'
import Editor from '../../../components/Editor.vue'

export default {
  components: {
    BCardTitle,
    BFormGroup,
    BFormCheckbox,
    Editor,
  },
  props: {
    templateSlug: {
      type: String,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: false,
      currentTemplate: {},
      parentTemplate: {},
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await Template.getOne(this.currentSite.slug, this.templateSlug)

    if (response.data) {
      response.data.content_template = response.data.content_template || ''
      this.currentTemplate = response.data

      if (this.currentTemplate.parent_slug) {
        this.parentTemplate = this.currentTemplate.parent
      }
      this.visible = true
    }
  },
  methods: {
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },
    async handleSubmit() {
      this.$nextTick(() => {
        this.$bvModal.hide('show-template-popup')
      })
    },
    highlightContent(content) {
      const text = content || ''
      return text
        .replace(/({{\s*?\w+\s*?}})/ig, '<code class="code-highlight code-highlight--success">$1</code>')
        .replace(/(\[\[\s*?[\w;\s]+\s*?\]\])/ig, '<code class="code-highlight code-highlight--success">$1</code>')
    },
  },
}
</script>
