<template>
  <div
    class="super-tag"
    :class="`super-tag--${tagType}`"
  >
    <feather-icon
      v-if="!disableIcon"
      icon="TagIcon"
      size="12"
      style="margin-right: 2px;"
    />
    <span v-if="hasSlot">
      <slot />
    </span>
    <span v-else>
      {{ tagType === 'primary' ? 'Единый' : '' }}
    </span>
  </div>
</template>
<script>
export default {
  props: {
    disableIcon: {
      type: Boolean,
      default: false,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    tagType() {
      if (this.level === 0) return 'primary'
      if (this.level === 1) return 'warning'
      if (this.level === 2) return 'success'
      if (this.level === 3) return 'gray'
      return 'primary'
    },
    hasSlot() {
      return this.$slots.default
    },
  },
}
</script>
