<template>
  <div>
    <div
      v-for="template in templates"
      :key="template.slug"
      class="accordion-table__body"
    >
      <div v-b-toggle="`accordion-${template.slug}`">
        <templates-table-item
          :level="level"
          :template="template"
        />
      </div>

      <b-collapse
        :id="`accordion-${template.slug}`"
        :visible="level === 0"
      >
        <div
          v-if="template.children.length"
          class="accordion-table__children"
        >
          <div class="accordion-table">
            <templates-table-list
              :templates="template.children"
              :level="level + 1"
              @loadMore="handleClickLoadMore"
            />
          </div>
        </div>
        <div class="ml-5 mb-5">
          <b-button
            variant="primary"
            :disabled="loadMoreButtonIsDisabled(template)"
            @click.prevent="handleClickLoadMore(template)"
          >
            {{ loadMoreButtonText(template) }}
          </b-button>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import { VBToggle, BCollapse, BButton } from 'bootstrap-vue'
import TemplatesTableItem from './TemplatesTableItem.vue'

export default {
  name: 'TemplatesTableList',
  components: {
    TemplatesTableItem,
    BCollapse,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    templates: {
      type: Array,
      required: true,
    },
    level: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    loadMoreButtonIsDisabled: () => template => template.meta.current_page >= template.meta.last_page,
    loadMoreButtonText: () => template => {
      if (template.meta.current_page === 0) {
        return 'Загрузить дочерние шаблоны'
      }

      if (template.meta.current_page >= template.meta.last_page) {
        return 'Больше нет дочерних шаблонов'
      }

      return 'Загрузить ещё'
    },
  },
  methods: {
    handleClickLoadMore(template) {
      this.$emit('loadMore', template)
    },
  },
}
</script>
