<template>
  <div>
    <div
      v-if="!disabled"
      class="editor-container"
    >
      <SVGSprite />
      <div
        v-if="editor && toolbar"
        class="menubar"
      >
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('bold') }"
          @click.prevent="editor.chain().focus().toggleBold().run()"
        >
          <icon
            name="bold"
          />
        </button>
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('italic') }"
          @click.prevent="editor.chain().focus().toggleItalic().run()"
        >
          <icon
            name="italic"
          />
        </button>
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('strike') }"
          @click.prevent="editor.chain().focus().toggleStrike().run()"
        >
          <icon
            name="strike"
          />
        </button>
        <button
          class="menubar__button"
          type="button"
          @click.prevent="showAddImagePopup = true"
        >
          <icon
            name="image"
          />
        </button>
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
          @click.prevent="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        >
          h1
        </button>
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
          @click.prevent="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        >
          h2
        </button>
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
          @click.prevent="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        >
          h3
        </button>
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
          @click.prevent="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        >
          h4
        </button>
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
          @click.prevent="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        >
          h5
        </button>
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
          @click.prevent="editor.chain().focus().toggleHeading({ level: 6 }).run()"
        >
          h6
        </button>
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('bulletList') }"
          @click.prevent="editor.chain().focus().toggleBulletList().run()"
        >
          <icon
            name="ul"
          />
        </button>
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('orderedList') }"
          @click.prevent="editor.chain().focus().toggleOrderedList().run()"
        >
          <icon
            name="ol"
          />
        </button>
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('code') }"
          @click.prevent="editor.chain().focus().toggleCode().run()"
        >
          <icon
            name="code"
          />
        </button>
        <button
          class="menubar__button"
          type="button"
          @click.prevent="editor.chain().focus().clearNodes().run()"
        >
          <icon
            name="remove"
          />
        </button>
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('paragraph') }"
          @click.prevent="editor.chain().focus().setParagraph().run()"
        >
          <icon
            name="paragraph"
          />
        </button>
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('codeBlock') }"
          @click.prevent="editor.chain().focus().toggleCodeBlock().run()"
        >
          <icon
            name="code"
          />
        </button>
        <button
          class="menubar__button"
          type="button"
          :class="{ 'is-active': editor.isActive('blockquote') }"
          @click.prevent="editor.chain().focus().toggleBlockquote().run()"
        >
          <icon
            name="quote"
          />
        </button>
        <button
          class="menubar__button"
          type="button"
          @click.prevent="editor.chain().focus().setHorizontalRule().run()"
        >
          <icon
            name="hr"
          />
        </button>
        <button
          class="menubar__button"
          type="button"
          @click.prevent="editor.chain().focus().undo().run()"
        >
          <icon
            name="undo"
          />
        </button>
        <button
          class="menubar__button"
          type="button"
          @click.prevent="editor.chain().focus().redo().run()"
        >
          <icon
            name="redo"
          />
        </button>
        <button
          class="menubar__button"
          type="button"
          @click.prevent="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"
        >
          <icon
            name="table"
          />
        </button>
      </div>
      <editor-content
        class="editor"
        spellcheck="false"
        :class="`editor-size--${size}`"
        :editor="editor"
      />
    </div>

    <div v-else>
      <div
        v-if="value"
        class="highlight-input ProseMirror"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <div v-html="value" />
      </div>
      <div v-else>
        Контент пустой
      </div>
    </div>

    <variables-list
      v-if="variables.length"
      class="mt-1"
      :variables="variables"
      @chose="insertVariable"
    />

    <b-modal
      id="add-image-popup"
      :visible="showAddImagePopup"
      size="sm"
      ok-title="Вставить"
      cancel-title="Отменить"
      @ok="InsertImage"
      @hidden="showAddImagePopup = false"
    >
      <b-card-title
        title-tag="h2"
      >
        Добавить изображение
      </b-card-title>
      <input-file
        @uploaded="currentImage = $event"
      />
    </b-modal>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'

import { defaultExtensions } from '@tiptap/starter-kit'
import Table from '@tiptap/extension-table'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import Image from '@tiptap/extension-image'

import { BCardTitle } from 'bootstrap-vue'
import VariablesList from './input-field-with-variables/VariablesList.vue'
import SVGSprite from '../views/templates/template-edit/components/SVGSprite.vue'
import Icon from '../views/templates/template-edit/components/Icon.vue'

import InputFile from '../@core/components/input/InputFile.vue'

export default {
  $_veeValidate: {
    name() {
      return this.name
    },
    value() {
      return this.value
    },
  },
  components: {
    EditorContent,
    VariablesList,
    SVGSprite,
    Icon,
    InputFile,
    BCardTitle,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    variables: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    toolbar: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      editor: null,
      showAddImagePopup: false,
      currentImage: {},
    }
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return
      }

      this.editor.commands.setContent(this.value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        ...defaultExtensions(),
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        Image,
      ],
      content: this.value,
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    insertVariable(variable) {
      this.editor.commands.insertText(`{{ ${variable} }}`)
    },
    InsertImage() {
      this.editor.chain().focus().setImage({ src: this.currentImage.url }).run()
      this.currentImage = {}
    },
    highlightContent(content) {
      const text = content || ''

      return text
        .replace(/<(\/?)(code)>/g, '')
        .replace(/({{\s*?\w+\s*?}})/ig, '<code class="code-highlight code-highlight--success">$1</code>')
        .replace(/(\[\[\s*?[\w;\s]+\s*?\]\])/ig, '<code class="code-highlight code-highlight--success">$1</code>')
    },
  },
}
</script>

<style lang="scss">
  @import "../assets/tiptap/scss/variables";
  @import "../assets/tiptap/scss/menubar";
  @import "../assets/tiptap/scss/editor";
</style>
